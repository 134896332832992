<template>
  <div v-if="content" class="reference-snippet" v-html="content" />
</template>

<script lang="ts" setup>
import { ref, watch } from "vue";

const props = withDefaults(
  defineProps<{
    cleanupDepth?: number;
    id: string;
    missingRefText?: string;
    range?: number;
    rawHtml: string;
    selector?: string;
  }>(),
  {
    cleanupDepth: 10,
    missingRefText: "…",
    range: 20,
    selector: "data-parameter-id",
  },
);

const content = ref<string>("");

const getTextContentLength = (node: Node) => {
  if (node instanceof Text) {
    const t = (node.textContent ?? "").trim();
    return t.length;
  }
  let length = 0;
  for (const child of node.childNodes) {
    length += getTextContentLength(child);
  }
  return length;
};

const alterTextNode = (node: Text, remainder: number, isBefore = true) => {
  if (remainder <= 0) {
    node.textContent = "";
  } else if (node.textContent) {
    const length = getTextContentLength(node);
    if (length <= remainder) {
      remainder -= length;
    } else {
      node.textContent = isBefore
        ? `…${node.textContent.slice(-remainder)}`
        : `${node.textContent.slice(0, remainder)}…`;
      remainder = 0;
      if (isBefore && node.parentElement instanceof HTMLElement && node.parentElement.nodeName === "LI") {
        node.parentElement.classList.add("reference-snippet__cutoff");
      }
    }
  }
  return remainder;
};

const cleanup = (doc: Document, depth = 3) => {
  if (depth <= 0) {
    return;
  }
  const walker = doc.createTreeWalker(doc.body, NodeFilter.SHOW_ALL);
  let beforeReference = true;
  while (walker.nextNode()) {
    if (!(walker.currentNode instanceof HTMLElement)) {
      continue;
    }
    if (walker.currentNode.classList.contains("reference-snippet__highlight")) {
      beforeReference = false;
      continue;
    }
    if (walker.currentNode.textContent === "" && !["TH", "TD"].includes(walker.currentNode.nodeName)) {
      if (
        beforeReference &&
        walker.currentNode.nodeName === "LI" &&
        walker.currentNode.parentElement?.nodeName === "OL"
      ) {
        const s = walker.currentNode.parentElement.getAttribute("start");
        const start = (typeof s === "string" ? parseInt(s) : 1) + 1;
        walker.currentNode.parentElement.setAttribute("start", start.toString());
      }
      walker.currentNode.remove();
    }
  }
  cleanup(doc, depth - 1);
};

watch(
  props,
  () => {
    const doc = new DOMParser().parseFromString(props.rawHtml, "text/html");
    const target = doc.querySelector(`[${props.selector}="${props.id}"]`);
    if (!target || !(target instanceof HTMLElement)) {
      return;
    }
    target.classList.add("reference-snippet__highlight");
    target.innerText = target.innerText.replaceAll("&nbsp;", "").trim();
    if (target.innerText === "") {
      target.innerText = props.missingRefText;
    }
    // if there is text around the reference we only use that
    if (target.parentElement && getTextContentLength(target.parentElement) > getTextContentLength(target)) {
      content.value = target.parentElement.innerHTML;
      return;
    }
    // if not we try to find the surrounding elements
    const walker = doc.createTreeWalker(doc.body, NodeFilter.SHOW_ELEMENT);
    const elements: HTMLElement[] = [];
    let afterRemainder = props.range;
    while (walker.nextNode()) {
      if (walker.currentNode instanceof HTMLElement && walker.currentNode.outerHTML === target.outerHTML) {
        let remainder = props.range;
        elements.reverse().forEach((el) => {
          let isBefore = true;
          for (const node of el.childNodes) {
            if (node instanceof HTMLElement && node.outerHTML === target.outerHTML) {
              isBefore = false;
            } else if (node instanceof Text && isBefore) {
              remainder = alterTextNode(node, remainder, isBefore);
            } else if (node instanceof Text) {
              afterRemainder = alterTextNode(node, afterRemainder, isBefore);
            }
          }
        });
        elements.length = 0;
      } else if (walker.currentNode instanceof HTMLElement) {
        elements.push(walker.currentNode);
      }
    }
    elements.forEach((el) => {
      for (const node of el.childNodes) {
        if (node instanceof Text) {
          afterRemainder = alterTextNode(node, afterRemainder, false);
        }
      }
    });
    cleanup(doc, props.cleanupDepth);
    content.value = doc.body.innerHTML;
  },
  { immediate: true },
);
</script>

<style lang="scss">
.reference-snippet {
  background-color: var(--menu-hover);
  border-radius: var(--app-radius-small);
  padding: 0.5rem;
  @include markdown-styling;
  &__cutoff::marker {
    color: var(--black-50);
  }
  &__highlight {
    background-color: var(--black-10);
    color: var(--black-50);
    display: inline-block;
    margin: 0 0.5rem;
    padding: 0.125rem 0;
    &::before {
      content: "[";
      color: var(--light-blue-vivid);
      left: -0.325rem;
      position: relative;
    }
    &::after {
      content: "]";
      color: var(--light-blue-vivid);
      right: -0.325rem;
      position: relative;
    }
  }
}
</style>
